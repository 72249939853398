export const BLOG = '/blog';
export const ABOUT = '/about';


export const FRESHCRIBBLE = '/freshcribble';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LANDING = '/';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
