import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Blog extends Component{
  render() {
    return (
      <div> 
        <h2>About</h2>
      </div>
    );
  }
}

export default withRouter(Blog);
