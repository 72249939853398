import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import FreshCribble from '../FreshCribble';
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import Blog from '../Blog';
import * as ROUTES from '../../constants/routes';
import NotLinked from '../NotLinked';

const App = () => (
  <Router>

    <div>
      <Link to="/">Home</Link>
      <Link to="/blog">Blooog</Link>
    </div>

    <Switch>

      <Route exact path={ROUTES.BLOG} component={Blog} />
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route exact path={ROUTES.FRESHCRIBBLE} component={FreshCribble} />
      <Route component={NotLinked} />

    </Switch>
  </Router>
);

export default App;


      // <Navigation />

    //   <hr />

    //   <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
    //   <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
    //   <Route
    //     exact
    //     path={ROUTES.PASSWORD_FORGET}
    //     component={PasswordForgetPage}
    //   />
    //   <Route exact path={ROUTES.HOME} component={HomePage} />
    //   <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
    //   <Route exact path={ROUTES.ADMIN} component={AdminPage} />